'use client'

import React from 'react';

interface VideoSchemaProps {
  videoAltText: string;
  desktopThumbnailUrl: string;
  mobileThumbnailUrl: string;
  desktopVideoUrl: string;
}

const VideoSchema: React.FC<VideoSchemaProps> = ({
  videoAltText,
  desktopThumbnailUrl,
  mobileThumbnailUrl,
  desktopVideoUrl
}) => {
  const videoSchema = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: videoAltText,
    description: videoAltText,
    thumbnailUrl: [mobileThumbnailUrl, desktopThumbnailUrl],
    uploadDate: new Date().toISOString(),
    contentUrl: desktopVideoUrl,
  };

  return (
    <script 
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(videoSchema) }}
    />
  );
};

export default VideoSchema;
